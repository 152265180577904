<template>
	<div>
		<CCard class="p-4 ">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow>
						<CCol lg="6">
							<h5 v-if="!editMode">New MTS / Booking #{{bookingParams.booking_no}}</h5>
							<h5 v-else>Update MTS /{{bookingParams.booking_no}}/ {{dataParams.mts_no}}</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right">
				      			<CButton size="sm" @click="backToTable()" color="danger"><i class="fa fa-close"></i> Cancel</CButton> &nbsp;
					      		<CButton size="sm" class="px-3" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="ml-4 mr-4 " >
					
						<fieldset class="full-line rounded">
							<legend> MTS Information</legend>
										<CRow >
											
											<CCol lg="6">
												<div class="form-group"> 
													<label class="mb-1">Dept / Proj <span class="text-danger">*</span></label>
													<v-select 
														label="customer_name" 
														:options="customerList.data"
														:reduce="item => item.id"
														v-model="dataParams.dept_id"
														placeholder="--Select--" 
													>
														<template #search="{attributes, events}">
															<input
																class="vs__search"
																:required="!dataParams.dept_id"
																v-bind="attributes"
																v-on="events"
															/>
														</template>
													</v-select>
												</div>
											</CCol> 
											<CCol lg="6">
												<div class="form-group"> 
													<label class="mb-1">Transfer Date <span class="text-danger">*</span></label>
													<Datetime format="MM-DD-YYYY" required v-model="dataParams.transfer_date" ></Datetime> 
														
												</div>
											</CCol>
											
										</CRow>
										<CRow > 
											<CCol lg="6">
												<div class="form-group"> 
													<label class="mb-1">Origin <span class="text-danger">*</span></label>
													<v-select 
														label="customer_name" 
														:options="originList.data"
														:reduce="item => item.id"
														v-model="dataParams.origin_id"
														placeholder="--Select--" 
													>
														<template #search="{attributes, events}">
															<input
																class="vs__search"
																:required="!dataParams.origin_id"
																v-bind="attributes"
																v-on="events"
															/>
														</template>
													</v-select>
												</div>
											</CCol>
												<CCol lg="6">
												<div class="form-group"> 
													<label class="mb-1">Destination <span class="text-danger">*</span></label>
													<v-select 
														label="customer_name" 
														:options="destinationList.data"
														:reduce="item => item.id"
														v-model="dataParams.destination_id"
														placeholder="--Select--" 
													>
														<template #search="{attributes, events}">
															<input
																class="vs__search"
																:required="!dataParams.destination_id"
																v-bind="attributes"
																v-on="events"
															/>
														</template>
													</v-select>
												</div>
											</CCol>
											
										</CRow>
										<CRow > 
											<CCol lg="4">
												<label class="mb-1">Ref No.</label>
												<CInput
												v-model="dataParams.ref_no"   
												/>
											</CCol>
											<CCol lg="4">
												<label class="mb-1">Driver</label>
												<CInput
												v-model="bookingParams.driver_id_label"  
												disabled  	
												/>
											</CCol>
											<CCol lg="4">
												<label class="mb-1">Plate No.</label>
												<CInput
												v-model="bookingParams.vehicle_id_label"  
												disabled  
												/>
											</CCol> 
										
										</CRow> 
						</fieldset>
						<fieldset class="full-line rounded">
							<legend> MTS Items</legend>
										<CRow v-for="(item, index) in dataParams.material_transfer_item" :key="index"> 
											<CCol lg="2">
												<div class="form-group"> 
													<label class="mr-2 mb-1">Asset Type <span class="text-danger">*</span> </label>
													<v-select 
														label="setting_name" 
														:options="[
															{
																id: 'furnishing',
																setting_name: 'Furnishing'
															},
															{
																id: 'equipment',
																setting_name: 'Equipment'
															},
															{
																id: 'vehicle',
																setting_name: 'Vehicle'
															}
															
														]"
														:reduce="item => item.id"
														v-model="dataParams.material_transfer_item[index].asset_type"
														placeholder="--Select--" 
													>
														<template #search="{attributes, events}">
															<input
																class="vs__search"
																:required="!dataParams.material_transfer_item[index].asset_type"
																v-bind="attributes"
																v-on="events"
															/>
														</template>
													</v-select>
												</div>
											</CCol> 
											<CCol lg="2">
												<label class="mb-1">Asset No <span class="text-danger">*</span></label>
												<div class="input-group mb-2">
													<input type="text" class="form-control px-2" :disabled="!dataParams.material_transfer_item[index].asset_type" @click="assetSearchModalTrigger(true, index)" v-model="dataParams.material_transfer_item[index].no" readonly>
												</div>
											</CCol>
											<!-- <CCol lg="2">
												<label>Asset No. <span class="text-danger">*</span></label>
												<input v-if="dataParams.material_transfer_item[index].asset_type == ''" type="text" disabled class="form-control" value="Select Asset Type">
												<v-select 
													label="asset_no" 
													:options="furnishingList.data"
													:reduce="item => item.asset_no"
													v-model="dataParams.material_transfer_item[index].no"
													placeholder="Asset No" 
													v-if="dataParams.material_transfer_item[index].asset_type == 'furnishing'"
													@input="changeAsset(index)"
												>
													<template #search="{attributes, events}" v-if="dataParams.material_transfer_item[index].asset_type == 'furnishing'">
														<input
															class="vs__search"
															:required="!dataParams.material_transfer_item[index].no"
															v-bind="attributes"
															v-on="events"
														/>
													</template>
												</v-select>
												<v-select 
													label="asset_no" 
													:options="equipmentList.data"
													:reduce="item => item.asset_no"
													v-model="dataParams.material_transfer_item[index].no"
													placeholder="Asset No" 
													@input="changeAsset(index)"
													v-if="dataParams.material_transfer_item[index].asset_type == 'equipment'" 
												>
													<template #search="{attributes, events}" v-if="dataParams.material_transfer_item[index].asset_type == 'equipment'">
														<input
															class="vs__search"
															:required="!dataParams.material_transfer_item[index].no"
															v-bind="attributes"
															v-on="events"
														/>
													</template>
												</v-select>  
												<v-select 
													label="asset_no" 
													:options="vehicleList.data"
													:reduce="item => item.asset_no"
													v-model="dataParams.material_transfer_item[index].no"
													placeholder="Asset No" 
													@input="changeAsset(index)"
													v-if="dataParams.material_transfer_item[index].asset_type == 'vehicle'" 
												>
													<template #search="{attributes, events}" v-if="dataParams.material_transfer_item[index].asset_type == 'vehicle'">
														<input
															class="vs__search"
															:required="!dataParams.material_transfer_item[index].no"
															v-bind="attributes"
															v-on="events"
														/>
													</template>
												</v-select>  
											</CCol> -->
											<CCol lg="1">
												<label class="mb-1">Qty <span class="text-danger">*</span></label>
												<CInput
												
												v-model="dataParams.material_transfer_item[index].qty"   
												:required="true"
												@keypress="isNumber($event)"
												/>
											</CCol>
											<CCol lg="2">
												<div class="form-group"> 
													<label class="mb-1">Unit <span class="text-danger">*</span></label>
													<v-select 
														label="id" 
														:options="[
															{id: 'unts'},
															{id: 'pcs'},
															{id: 'ltrs'},
															{id: 'mtrs'},
															{id: 'lot'},
															{id: 'box'},
															{id: 'set'},
															{id: 'drum'}
														]"
														:reduce="item => item.id"
														v-model="dataParams.material_transfer_item[index].unit"
														placeholder="" 
													>
														<template #search="{attributes, events}">
															<input
																class="vs__search"
																:required="!dataParams.material_transfer_item[index].unit"
																v-bind="attributes"
																v-on="events"
															/>
														</template>
													</v-select>
												</div>
											</CCol> 
											<CCol lg="5">
												<label class="mb-1">Particular <span class="text-danger">*</span></label>
												<CInput
									
												:value="dataParams.material_transfer_item[index].particular_holder"  
												@change="setParticular($event, index)"
												:required="true" 
												/>
											</CCol>
												<CCol lg="4">
												<label class="mb-1">Brand</label>
												<CInput
													v-model="dataParams.material_transfer_item[index].brand"
												
												disabled
												/>
											</CCol>
												<CCol lg="3">
												<label class="mb-1">Serial No</label>
												<CInput
												
													v-model="dataParams.material_transfer_item[index].serial_no"
												disabled
												/>
											</CCol>
											<CCol lg="1" v-if="index != 0"> 
												<br/>
												<CButton size="sm" color="danger mt-2" @click="deleteItem(index)"><i class="fa fa-trash"></i></CButton> 
											</CCol>
										</CRow>
										<CRow >
											<CCol lg="2">
												
												<CButton size="sm" color="success" @click="addItem"><i class="fa fa-plus"></i> New Item</CButton>
											</CCol>
										</CRow>
										<br/>
										
										
										<CRow> 
											<CCol lg="12">
												<label class="mb-1">Remarks</label>
												<CInput
												
												v-model="dataParams.remarks"   
												/>
											</CCol>
											
										</CRow>
						</fieldset>
						<fieldset class="full-line rounded">
							<legend> References</legend>
										<CRow> 
											<CCol lg="6">
												<label class="mb-1">Prepared By</label>
												<CInput
												
												v-model="dataParams.prepared_by"   
												/>
											</CCol>
											<CCol lg="6">
												<label class="mb-1">Approved By</label>
												<CInput
												
												v-model="dataParams.approved_by"   
												/>
											</CCol> 
										</CRow> 

						</fieldset>	
						
				</CCardBody>
			</form>
		</CCard>

		<CModal :title="'Asset No. List'" :show.sync="showAssetModal" color="info" size="lg"> 
			<div class="pl-3 pr-3 pb-3"> 
				<CRow class="mb-1">
					<CCol lg="12">
						<div class="input-group mb-1">
							<input type="search" :value="previous_val" class="form-control" ref="asset_no_search" placeholder="Enter asset # to search.">
							<div class="input-group-append">
								<a href="javascript:void(0)" class="btn btn-primary" @click="searchAssetNo">
									<i class="fa fa-search"></i> Search
								</a>
							</div>
							
						</div>
					</CCol> 
				</CRow> 
				<CRow class="mb-2">
					<CCol lg="12">
						<small v-if="is_searching">
								<i class="fa fa-refresh fa-spin"></i> Searching ...
							</small>
						<div v-if="asset_no_list_holder.length">
							<div class="row mb-0" v-if="!is_searching">
								<div class="col-md-6 mb-0 pt-1">
									<small v-if="asset_no_list_holder.length == asset_no_list.length">
										<span class="text-success font-w600">{{asset_no_list_holder.length}}</span> record/s found.
									</small>
									<small v-else>
										<span class="text-success font-w600">{{asset_no_list.length}} <em class="text-muted">of</em> {{asset_no_list_holder.length}}</span> record/s found.
									</small>
								</div>
								<div class="col-md-6 mb-0">
									<input type="search" @keyup="filterList($event)" @search="filterList($event)" class="form-control form-control-sm float-right col-md-6" placeholder="Search list">
								</div>
							</div>
							
							<div class="list-group mt-1" style="max-height:500px;overflow-y:auto" >
								<a href="javascript:void(0)" class="list-group-item list-group-item-action flex-column align-items-start" v-for="(asset, index) in asset_no_list" :key="index">
									<div class="d-flex w-100 justify-content-between">
									<h4 class="mb-0">{{asset.asset_no}}</h4>
									<template v-if="isSelected(asset)">
										<button class="px-2 btn btn-sm btn-success" @click="selectAssetNo(asset)"><i class="fa fa-check "></i> Select</button>
									</template>
									<template v-else>
										<button class="px-2 btn btn-sm btn-secondary" disabled><i class="fa fa-check "></i> Selected</button>
									</template>
									</div>
									<small class="text-muted"><em>{{asset.description}}</em></small>
								</a>
							</div>
						</div>
						<div class="list-group" v-else>
							<label v-if="!is_searching" class="mb-0"><em>Nothing to display.</em></label>
						</div>
					</CCol> 
				</CRow> 
			</div>
			<div slot="footer" class="w-100">
				<CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="assetSearchModalTrigger(false)">
					Close
				</CButton>  
			</div>
			<div slot="footer" class="w-100"></div>
		</CModal>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import Datetime from 'vuejs-datetimepicker'; 
import moment from 'moment';

export default {
	mounted(){   
		 
		if(this.$route.params.mts_id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.mts_id){
			this.getData();
		}   
		this.getOrigin();
		this.getDestination();
		this.getBooking();
		this.getCustomer();

		// this.getEquipment();
		// this.getFurnishing();

		// this.getVehicle()
		
	},
	data(){
		return{
			bookingParams: {
				booking_no: "", 
				plate_no:"",
				driver_id:"",
				driver: {
					name: ""
				},
				vehicle: {
					plate_no: ""
				}  
			},
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "", 
			particular_holder : [],
			dataParams:{
				dept_id: "",
				origin_id: "",
				destination_id: "",
				transfer_date: "",
				remarks: "",
				ref_no: "",
				prepared_by: "",
				approved_by: "",
				mts_no: "",
				material_transfer_item: [
					{
						asset_type: "",
						no: "",
						qty: "",
						unit: "units",
						particular: "",
						brand: "",
						serial_no: ""
					}
				]
			},
			vehicle_id: "",
			vehicle_type_id: "",
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			
			
			isLoading: false,
			uploadPercentage: 0,
			config,


			customerList: {
				data: []
			},
			bookingTypeList: {
				data: []
			},
			commodityTypeList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			driverList: {
				data: []
			},
			helperList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			equipmentDetails: {},
			furnishingDetails: {},
			vehicleDetails : {},
			showAssetModal : false,
			dr_index: null,
			asset_type_selected: null,
			asset_no_list: [],
			asset_no_list_holder: [],
			previous_val : "",
			is_searching : false,
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Datetime},
	methods: {
		filterList(ev) {
			let qry = ev.target.value;
			this.asset_no_list = [...this.asset_no_list_holder]

			if(qry?.length) {
				qry = qry.toLowerCase();
				this.asset_no_list = this.asset_no_list.filter(item => item.asset_no.toLowerCase().includes(qry))
			}
		},
		isSelected(asset) {
			return !(this.dataParams.material_transfer_item[this.dr_index]?.no == asset.asset_no)
		},
		selectAssetNo(asset) {
			if(this.asset_type_selected == 'vehicle') {
				this.dataParams.material_transfer_item[this.dr_index].no = asset.asset_no;
				this.showAssetModal = false;
				this.dataParams.material_transfer_item[this.dr_index].brand = asset.maker_model;
				this.dataParams.material_transfer_item[this.dr_index].serial_no = asset.engine_no;
				this.dataParams.material_transfer_item[this.dr_index].particular_holder =`${asset.vehicle_type} (${asset.plate_no})`;
			}
			else {
				this.dataParams.material_transfer_item[this.dr_index].no = asset.asset_no;
				this.showAssetModal = false;
				this.dataParams.material_transfer_item[this.dr_index].brand = asset.mfg_brand;
				this.dataParams.material_transfer_item[this.dr_index].serial_no = asset.serial_no;
				this.dataParams.material_transfer_item[this.dr_index].particular_holder = asset.description;
			}
			this.asset_no_list = [];
			this.asset_no_list_holder = [];
			
		},
		searchAssetNo() {
			let query = this.$refs.asset_no_search.value;
			if(query?.trim()?.length == 0) {
				this.asset_no_list = [];
				this.asset_no_list_holder = [];
				return;
			}

			this.previous_val = query;
			this.is_searching = true;

			let url = `${config.api_path}/reference/equipment-list`;
			if(this.asset_type_selected == 'furnishing')
				url = `${config.api_path}/reference/furnishing-list`;
			if(this.asset_type_selected == 'vehicle')
				url = `${config.api_path}/reference/vehicle-list`;

			axios.get(url, {
				params: {query}
			}).then(res => {
				const {data, status} = res.data;
				this.asset_no_list = data;
				this.asset_no_list_holder = data;
				this.is_searching = false;
			}).catch(err => {
				this.is_searching = false;
			})
		},
		assetSearchModalTrigger(show, index) {
			this.showAssetModal = show;
			this.asset_type_selected = show ? this.dataParams.material_transfer_item[index].asset_type : null;
			this.dr_index = index;
			this.previous_val = this.dataParams.material_transfer_item[index].no ?? '';
			this.$nextTick(() => {
				this.$refs.asset_no_search.focus()
			})
			
		},  
		setParticular(ev, index) {
			this.dataParams.material_transfer_item[index].particular = ev;
		},
	    submit(){
	    	var ax = {}; 

	    	this.dataParams.transfer_date = moment(this.dataParams.transfer_date, "MM-DD-YYYY").startOf('day').valueOf() / 1000;

			
			if(this.dataParams.material_transfer_item.length) {
				for(const [index, item] of this.dataParams.material_transfer_item.entries()) {
					if(!item.particular) this.dataParams.material_transfer_item[index].particular = this.dataParams.material_transfer_item[index].particular_holder
				}
			}
			
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/materialTransfer/"+this.$route.params.mts_id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/materialTransfer", this.dataParams)
	    	}
	    	ax.then(response => {
	    		var text = 'Material transfer successfully added!';
	    		if(this.editMode){
	    			text = 'Material transfer successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					dept_id: "",
					origin_id: "",
					destination_id: "",
					transfer_date: "",
					remarks: "",
					ref_no: "",
					prepared_by: "",
					approved_by: "",
					mts_no: "",
					material_transfer_item: [
						{
							asset_type: "",
							no: "",
							qty: "",
							unit: "units",
							particular: "",
							brand: "",
							serial_no: ""
						}
					]
				}
	    		this.$router.push('/booking/'+this.$route.params.id+'/view_mts/'+response.data.data.id)
	    	})
	    },

	    backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/mts')
	    }, 

		

	    getOrigin(){
            axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
            .then(response => {
				this.originList = response.data; 
              
            })

	    },

	    // 	axios.get(config.api_path+'/customer?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.originList = response.data; 
	    // 	})

	    // },

	    getDestination(){

			axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
            .then(response => {
				this.destinationList = response.data; 
              
            })

	    },
	    // 		axios.get(config.api_path+'/customer?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.destinationList = response.data; 
	    // 	})

	    // }, 

	    getCustomer(){
	    	axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})

	    }, 

	    
	    
	    getData(){
			this.$showLoading(true)

			axios.get(config.api_path+"/materialTransfer/"+this.$route.params.mts_id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data; 
				this.dataParams.material_transfer_item = this.dataParams.material_transfer_item.map(item => {
					item.particular_holder = item.particular;
					return item;
				})

				console.log(this.dataParams.material_transfer_item)
				
				this.dataParams.transfer_date = parseInt(this.dataParams.transfer_date)
				// this.dataParams.transfer_date = moment.unix(this.dataParams.transfer_date).format('MMMM DD, YYYY'); alert(this.dataParams.transfer_date)
			})

		}, 

		getBooking(){
			this.$showLoading(true)
			axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{ 
				this.$showLoading(false) 
				this.bookingParams = response.data.data;  
				this.dataParams.delivery_date = moment.unix(this.bookingParams.delivery_date).format('MMMM DD, YYYY')
				this.dataParams.address = this.bookingParams.customer.address_a;
				this.dataParams.booking_id = this.$route.params.id;
				this.dataParams.customer_id = this.bookingParams.customer_id;
				this.dataParams.delivered_by = this.bookingParams.driver_id;

				this.bookingParams.driver_id_label = "N/A";
				if(this.bookingParams.driver){
					this.bookingParams.driver_id_label = this.bookingParams.driver.name
				}

				this.bookingParams.vehicle_id_label = "N/A";
				if(this.bookingParams.vehicle){
					this.bookingParams.vehicle_id_label = this.bookingParams.vehicle.plate_no
				}
			})
		}, 

		addItem(){
			this.dataParams.material_transfer_item.push({
				asset_type: "",
				no: "",
				qty: "",
				unit: "units",
				particular: "",
				brands: "",
				serial_no: ""
			})
		},

		deleteItem(index){
			this.dataParams.material_transfer_item.splice(index, 1);
		},

		// getEquipment(){

	    // 	axios.get(config.api_path+'/equipment?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.equipmentList = response.data;
	    // 		this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			// value.label = value.equipment_prep_no;
		// 			value.label = value.asset_no;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

		changeAsset(i) {
			console.log('INDEX', i)
			var material_transfer_item = this.dataParams.material_transfer_item;
			if(material_transfer_item[i].asset_type == 'furnishing'){
				var asset = this.furnishingList.data.filter((value, index)=>{
					return value.asset_no == material_transfer_item[i].no;
				})
				if(asset.length > 0){
					material_transfer_item[i].particular_holder = asset[0].description;

					// if(material_transfer_item[i].particular == ""){
					// 	material_transfer_item[i].particular = asset[0].description;
					// 	material_transfer_item[i].particular_holder = material_transfer_item[i].particular
					// } 
					material_transfer_item[i].brand = asset[0].mfg_brand;
					material_transfer_item[i].serial_no = asset[0].serial_no;
				}
			}
			else if(material_transfer_item[i].asset_type == 'equipment'){
				var asset = this.equipmentList.data.filter((value, index)=>{
					return value.asset_no == material_transfer_item[i].no;
				})
				if(asset.length > 0){
					material_transfer_item[i].particular_holder = asset[0].description;
					// if(material_transfer_item[i].particular == ""){
					// 	material_transfer_item[i].particular = asset[0].description;
					// 	material_transfer_item[i].particular_holder = material_transfer_item[i].particular
					// } 
					material_transfer_item[i].brand = asset[0].mfg_brand;
					material_transfer_item[i].serial_no = asset[0].serial_no;
				}
			}
			else if(material_transfer_item[i].asset_type == 'vehicle') {
				var asset = this.vehicleList.data.filter((value, index)=>{
					return value.asset_no == material_transfer_item[i].no;
				})
				if(asset.length > 0){
					let plate_no = (asset[0].plate_no) ? asset[0].plate_no : 'N/A';
					material_transfer_item[i].particular_holder = `${asset[0].vehicle_type.setting_name} (${plate_no})` ; //vehicle type (plate no)
					material_transfer_item[i].brand = (asset[0].maker_model) ? asset[0].maker_model.setting_name : ''; // maker_model
					material_transfer_item[i].serial_no = asset[0].engine_no; //engine_number
				}

			}
		},

	    // getFurnishing(){
	    // 	axios.get(config.api_path+'/furnishing?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.furnishingList = response.data;
	    // 		this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.asset_no;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

		// getVehicle(){
	    // 	axios.get(config.api_path+'/vehicle?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		let data = response.data.data;
		// 		let holder = [];
		// 		data.forEach(element => {
		// 			if(element.asset_no && element.asset_no !== 'null') holder.push(element)
		// 		});
		// 		this.vehicleList.data = holder;
	    // 		this.vehicleList.data = this.vehicleList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.asset_no;
	    // 			return value;
	    // 		})
	    // 	})
	    // },

 	}, 

 	watch: {
		// dataParams: {
		// 	// This will let Vue know to look inside the array
		// 	deep: true,

		// 	// We have to move our method to a handler field
		// 	handler(){
		// 		var material_transfer_item = this.dataParams.material_transfer_item;
		// 		for(var i = 0; i < material_transfer_item.length; i++){
		// 			if(material_transfer_item[i].asset_type == 'furnishing'){
		// 				var asset = this.furnishingList.data.filter((value, index)=>{
		// 					return value.asset_no == material_transfer_item[i].no;
		// 				})
		// 				if(asset.length > 0){
		// 					// material_transfer_item[i].particular_holder = asset[0].description;

		// 					if(material_transfer_item[i].particular == ""){
		// 						material_transfer_item[i].particular = asset[0].description;
		// 						material_transfer_item[i].particular_holder = material_transfer_item[i].particular
		// 					} 
		// 					material_transfer_item[i].brand = asset[0].mfg_brand;
		// 					material_transfer_item[i].serial_no = asset[0].serial_no;
		// 				}
		// 			}
		// 			if(material_transfer_item[i].asset_type == 'equipment'){
		// 				var asset = this.equipmentList.data.filter((value, index)=>{
		// 					return value.asset_no == material_transfer_item[i].no;
		// 				})
		// 				if(asset.length > 0){
		// 					// material_transfer_item[i].particular_holder = asset[0].description;
		// 					if(material_transfer_item[i].particular == ""){
		// 						material_transfer_item[i].particular = asset[0].description;
		// 						material_transfer_item[i].particular_holder = material_transfer_item[i].particular
		// 					} 
		// 					material_transfer_item[i].brand = asset[0].mfg_brand;
		// 					material_transfer_item[i].serial_no = asset[0].serial_no;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
    } 

}
</script>
